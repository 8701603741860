import request from '@/utils/request'

//智参客户企业管理列表一览
export function companylist(query) {
  return request({
    url: '/companyGinseng/listPage',
    method: 'get',
    params: query
  })
}
//导入企业
export function importCompany(data) {
  return request({
    url: '/companyGinseng/files', 
    method: 'post',
   data
  })
}
// 智参客户管理详情
export function getCompanyDetail(query) {
    return request({
      url: '/companyGinseng/listById',
   
      method: 'get',
      params: query
    })
  }
  
  //新增企业形象
export function addcompany(data) {
    return request({
      url: '/companyGinseng/save', 
      method: 'post',
      data: data
    })
  }
  //修改
  export function editcompany(data) {
    return request({
      url: '/companyGinseng/update', 
      method: 'post',
      data: data
    })
  }
  //企业关联用户
  
export function getCorrelateUser(query) {
    return request({
      url: '/companyGinseng/clientCompanyApiUserListPage',  
      method: 'get',
      params: query
    })
  }
  //获取企业信息详情
export function getcompany(id) {
    return request({
      url: '/company/' + id,
      method: 'get'
    })
  }
  //企业诊断记录一览
  export function listDiagnosePage(query) {
    return request({
      url: "/companyGinseng/listDiagnosePage",
      method: "get",
      params: query
    })
  }
//诊断记录一览
export function getDiagnosticTools(data) {
  return request({
    url: "/questionnaire/getDiagnosticTools",
    method: "post",
    data
  })
}
//诊断详情
export function selectDiagnose(query) {
  return request({
    url: '/questionnaire/selectDiagnoseTwo',
    method: 'get',
    params: query
  })
}
// export function selectDiagnose(query) {
//   return request({
//     url: '/questionnaire/selectDiagnose',
//     method: 'get',
//     params: query
//   })
// }
//根据id查询信息
export function selectById(query) {
  return request({
    url: '/webUser/selectById',
    method: 'get',
    params: query
  })
}
//发布报告
export function updateRelease(query) {
  return request({
    url: '/questionnaire/updateRelease',
    method: 'post',
    params: query
  })
}
//获取申报政策
export function selectPolicyDeclaration(data) {
  return request({
    url: "/questionnaire/selectPolicyDeclarationTwo",
    method: "post",
    data
  })
}
//获取分析报告
export function selectReport(data) {
  return request({
    url: "/questionnaire/selectReport",
    method: "post",
    data
  })
}
//历史诊断记录
export function selectDiagnosticLogout(data) {
  return request({
    url: "/questionnaire/selectDiagnosticLogout",
    method: "post",
    data
  })
}
//修改诊断书建议
export function updateDiagnose(data) {
  return request({
    url: "/questionnaire/updateDiagnose",
    method: "post",
    data
  })
}
//手动修改申报政策
export function updateolicyState(data) {
  return request({
    url: "/questionnaire/updateDeclarationPolicyState",
    method: "post",
    data
  })
}
//下载诊断政策doc文档
export function downloadPdf(data) {
  return request({
    url: "/questionnaire/downloadPdf",
    method: "post",
    responseType: "blob",
    data
  })
}
//下载诊断政策doc文档
export function downloadPdfReport(data) {
  return request({
    url: "/questionnaire/downloadPdfReport",
    method: "post",
    // responseType: "blob",
    data
  })
}
//预约记录
export function diagnosticAppointment(data) {
  return request({
    url: "/questionnaire/diagnosticAppointment",
    method: "post",
    data
  })
}
//预约排期
export function updateIsSchedule(query) {
  return request({
    url: "/questionnaire/updateIsSchedule",
    method: "get",
    params: query
  })
}
//客户管理报备
export function companyReport(data) {
  return request({
    url: "/companyGinseng/companyReport",
    method: "post",
    data
  })
}
//移除
export function deleteById(query) {
  return request({
    url: "/companyGinseng/deleteById",
    method: "DELETE",
    params: query
  })
}
