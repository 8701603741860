<template>
  <div class="canvas_easy_picture" id="easy_picture" ref="imageTofile">
    <div class="first_title">{{ data.questionnaireName }}</div>
    <div class="card">
      <div class="card_top">
        <div class="enterprise_title">
          <img src="../../../assets/image/apply.png" alt="" />
          <div>{{ data.companyName }}</div>
        </div>
        <!-- <div class="industry_row">行业：农、林、牧、渔业</div> -->
      </div>
      <div class="card_content">
        <div class="card_item">
          <div class="item_head_row">
            <div class="item_title">诊断依据</div>
          </div>
          <div class="item_txt">
            {{ data.diagnosticBasis }}
          </div>
          <!-- 以下评估结果依据《优质中小企业提度培育管理管行办法》，由平台根据企业选填的信息综合评测形成。该结果为平台初步评估结论，仅作为企业认定专精特新政策参考。如需要精准评估，请联系平台。 -->
        </div>
        <div class="card_item">
          <div class="item_head_row analysis_item">
            <div class="item_title">分析图例</div>
            <div class="row_right">
              得分
              <span class="score">{{ data.score }}分</span>
            </div>
          </div>
          <div class="img_list">
            <!-- crossOrigin="anonymous" -->
            <!--  v-for="(item, index) in data.imgList" :key="index" -->
            <div v-for="(item, index) in myChartList" :key="index" class="img_item">
              <div class="item_name">
                <div class="img_box">
                  <img v-if="index === 0" src="../../../assets/image/sum.png" alt="" />
                  <img v-else-if="index === 1" src="../../../assets/image/planning.png" alt="" />
                  <img v-else-if="index === 2" src="../../../assets/image/base.png" alt="" />
                  <img v-else src="../../../assets/image/apply.png" alt="" />
                </div>
                <!-- item.name -->
                <span>{{ item.data.name }}</span>
              </div>
              <!-- 雷达图 -->
              <div class="dialog_echart" style="width: 100%; min-height: 300px; margin: auto"></div>
              <!-- <img :src="item.img" alt="" /> -->
            </div>
          </div>
        </div>
        <div class="card_item" v-if="data.isPolicy.length > 0">
          <div class="item_head_row">
            <div class="item_title">可申报的政策</div>
          </div>
          <div class="policy_list">
            <div class="policy_item" v-for="item in data.isPolicy" :key="item.id">
              <div class="policy_title">
                <div>
                  <i class="el-icon-success"></i>
                  <span>{{ item.declarationPolicyName }}</span>
                </div>
                <div class="row_right">
                  <i class="el-icon-caret-top"></i>
                </div>
              </div>
              <div class="label">可能会获得的扶持：</div>
              <div class="item_txt">{{ item.supportStandard }}</div>
            </div>
          </div>
        </div>
        <div class="card_item" v-if="data.noPolicy.length > 0">
          <div class="item_head_row">
            <div class="not_policy">不可申报的政策</div>
          </div>
          <div class="not_policy_list">
            <div class="not_policy_item" v-for="item in data.noPolicy" :key="item.id">
              <i class="el-icon-success"></i>
              <span>{{ item.declarationPolicyName }}</span>
            </div>
          </div>
        </div>
        <div class="card_item">
          <div class="item_head_row">
            <div class="item_title">综合评估建议</div>
          </div>
          <div class="item_txt has_border">
            {{ data.evaluationSuggestion }}
          </div>
          <!-- 根据企业填报数据及现有条件分析，企业专精特新发展能力综合温度指数为83℃。企业特长优势明显，符合专精特新发展方向。 -->
        </div>
        <div class="card_item">
          <div class="item_head_row">
            <div class="item_title">专家建议</div>
          </div>
          <div class="item_txt has_border">专家建议需要进一步沟通之后给出~</div>
        </div>
        <div class="submit_row">提交人：{{ data.name }}</div>
        <div class="submit_row">提交时间：{{ data.createTime }}</div>
      </div>
    </div>
    <a href="" style="display: none" id="download_link"></a>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
export default {
  name: 'EasyImageCanvas',
  props: {
    data: {
      type: Object
    },
    myChartList: {
      type: Array
    },
    bol: {
      type: Boolean
    }
  },
  data() {
    return {
      htmlUrl: '', //html2canvas绘制好的图片地址
      isShow: false //在页面dom渲染完毕后让图片显示在用户界面
    }
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.drawLine()
    })
  },
  watch: {
    // bol: function (newVal) {
    //   console.log(newVal)
    //   if (newVal) {
    //     this.$nextTick(() => {
    //       this.downloadImg()
    //     })
    //   }
    // }
  },
  methods: {
    // 绘制雷达图
    drawLine() {
      let specificEchart = document.getElementsByClassName('dialog_echart')
      this.myChartList.forEach((item, index) => {
        let myChart = this.$echarts.init(specificEchart[index])
        let option = {
          grid: {
            // 设置图表距离顶部,左侧，右侧和底部的高度
            top: '0px',
            left: '0px',
            right: '0px',
            bottom: '0px'
          },
          color: ['#68A2FF', '#FF9C00', '#0062FF'],
          //配置维度的最大值
          radar: {
            axisName: {
              axisName: {
                color: '#0062FF'
                //backgroundColor: '#999',
                //borderRadius: 3,
                // padding: [3, 5]
              },
              //指示器文字换行 start
              formatter: function (text) {
                var strlength = text.length
                if (strlength % 15 != 0) {
                  text = text.replace(/\S{15}/g, function (match) {
                    //console.log(match)
                    return match + '\n'
                  })
                } else {
                  text = text.replace(/\S{15}/g, function (match) {
                    //console.log(match)
                    return match + '\n'
                  })
                  strlength = text.length
                  text = text.substring(0, strlength - 1)
                }
                return text
              }
            },
            center: ['50%', '50%'], //调位置  第一个左右   第二个上下
            radius: 90, //调大小
            // 雷达图的指示器，用来指定雷达图中的多个变量（维度）
            indicator: item.dataMax,
            //对雷达图形设置成一个圆形,可选 circle:圆形,polygon:多角形(默认)
            shape: 'circle',
            splitArea: {
              itemStyle: {
                //color: '#FF7A00',
                //borderColor: '#FF7A00'
              },
              areaStyle: {
                // 图表背景网格区域的颜色
                //color: 'rgba(71,237,252,.3)'
                //color: '#FF7A00', //分割区域的背景色
              }
            },
            axisLine: {
              show: true, //雷达图中心发出的射线：坐标轴轴线相关设置
              lineStyle: {
                type: 'dashed',
                color: 'rgba(104,162,255,0.9)'
              }
            },
            splitLine: {
              //圆圈线条样式
              lineStyle: {
                color: 'rgba(104,162,255,.9)'
              }
            }
          },
          series: [
            {
              type: 'radar',
              label: {
                // 指示文字
                show: false, //控制是否显示数值
                fontSize: 10,
                color: '#FF7A00'
                // margin: 40,
                // textAlign:'center'
              },
              // areaStyle: {}, //每个雷达图形成一个阴影的面积
              itemStyle: {
                //折线拐点标志的样式
                color: '#FF7A00',
                borderColor: '#FF7A00'
              },
              areaStyle: {
                // 折线围成区域的颜色
                color: '#FF7A00' //分割区域的背景色
              },
              lineStyle: {
                //线条样式
                color: '#FF7A00'
              },
              symbolSize: 3, //圆点大小
              symbol: 'circle', //圆点样式
              data: [item.data]
            }
          ]
        }
        myChart.setOption(option) // 绘制图表
      })
      setTimeout(() => {
        // 调用画图并下载方法
        this.downloadImg()
      }, 2000)
    },
    // 画图并下载
    downloadImg() {
      const shareContent = document.getElementById('easy_picture') //需要截图的包裹的（原生的）DOM 对象
      // const width = shareContent.offsetWidth //获取dom 宽度
      const width = shareContent.offsetWidth
      const height = shareContent.offsetHeight //获取dom 高度
      let canvas = document.createElement('canvas') //创建一个canvas节点
      // const scale = window.devicePixelRatio //定义任意放大倍数 支持小数
      let scale = 1 //定义任意放大倍数 支持小数
      canvas.width = width * scale //定义canvas 宽度 * 缩放
      canvas.height = height * scale //定义canvas高度 *缩放
      canvas.style.width = width + 'px'
      canvas.style.height = height + 'px'
      canvas.getContext('2d').scale(scale, scale) //获取context,设置scale

      let opts = {
        // dpi: window.devicePixelRatio * 2,
        scale: scale, // 添加的scale 参数
        useCORS: true,
        canvas: canvas, //自定义 canvas
        logging: true //日志开关
        // width: width, //dom 原始宽度
        // height: height, //dom 原始高度
        // backgroundColor: 'null',
      }
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.imageTofile, opts).then((canvas) => {
        // let url = canvas.toDataURL('image/png')
        // this.htmlUrl = url
        // this.$nextTick(() => {
        //   this.isShow = true //绘图完毕让图片显示
        // })
        if (window.navigator.msSaveBlob) {
          let blob = canvas.msToBlob()
          /*这里直接改后缀修改格式*/
          window.navigator.msSaveBlob(blob, 'image/png')
        } else {
          let url = canvas.toDataURL() //把canvas转成base64
          // 写一个隐藏的a标签，借助a标签的download属性下载图片
          document.querySelector('#download_link').href = url
          document.querySelector('#download_link').download = 'image'
          document.querySelector('#download_link').click()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.canvas_easy_picture {
  width: 560px;
  padding: 30px 20px;
  box-sizing: border-box;
  background-image: url('../../../assets/image/easy-pictrue-bc-2.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .first_title {
    width: 100%;
    font-size: 20px;
    font-weight: 550;
    line-height: 26px;
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
  }
  .card {
    width: 100%;
    display: flex;
    flex-flow: column;
    border: #fff 2px solid;
    border-radius: 4px;
    // background-color: #68a2ff;
    background-image: url('../../../assets/image/easy-pictrue-bc-1.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 16px 12px;
    box-sizing: border-box;
    .card_top {
      width: 100%;
      display: flex;
      flex-flow: column;
      align-items: center;
      border-radius: 8px;
      // box-shadow: 0px 4px 4px 0px #68a2ff66;
      border-bottom: #1e9fff33 1px solid;
      // margin-bottom: 0.1px;
      padding: 20px;
      box-sizing: border-box;
      background-color: #fff;
      .enterprise_title {
        display: flex;
        align-items: center;
        text-align: center;
        font-size: 18px;
        font-weight: 550;
        line-height: 26px;
        color: #0062ff;
        img {
          width: 24px;
          height: 24px;
          margin-right: 12px;
        }
      }
    }
    .card_content {
      width: 100%;
      padding: 0 10px;
      box-sizing: border-box;
      .row_right {
        margin-left: auto;
      }
      .submit_row {
        font-size: 12px;
        line-height: 16px;
        color: #287fff;
        margin-bottom: 10px;
      }
      .card_item {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 4px;
        background-color: #fff;
        margin-bottom: 20px;
        // box-shadow: 0px 4px 4px 0px #68a2ff66;
        .analysis_item {
          border-bottom: #68a2ff 1px dotted;
        }
        .item_head_row {
          display: flex;
          font-size: 16px;
          font-weight: 550;
          line-height: 22px;
          padding-bottom: 10px;
          .item_title {
            color: #0062ff;
          }
          .not_policy {
            color: #fd678d;
          }

          .row_right {
            font-size: 12px;
            line-height: 22px;
            color: #ccc;
            .score {
              font-size: 16px;
              font-weight: 550;
              color: #ff7a00;
            }
          }
        }
        .has_border {
          border-radius: 4px;
          border: #0062ff 1px solid;
          padding: 10px;
          box-sizing: border-box;
        }
        .item_txt {
          font-size: 14px;
          line-height: 20px;
          color: #212121;
        }
        .img_list {
          width: 100%;
          display: flex;
          flex-flow: column;
          margin-top: 10px;
          .img_item {
            width: 100%;
            display: flex;
            flex-flow: column;
            .item_name {
              display: flex;
              align-items: center;
              .img_box {
                width: 16px;
                height: 16px;
                margin-right: 5px;
                img {
                  width: 16px;
                  height: 16px;
                }
              }
              span {
                font-size: 16px;
                font-weight: 500;
                color: #0062ff;
              }
            }
          }
          img {
            width: 100%;
            height: auto;
            margin-bottom: 16px;
          }
        }
        .not_policy_list,
        .policy_list {
          display: flex;
          flex-flow: column;
          width: 100%;
        }
        .policy_list {
          .policy_item {
            width: 100%;
            margin-bottom: 16px;
            .policy_title {
              display: flex;
              font-size: 14px;
              line-height: 20px;
              color: #212121;
              padding-bottom: 10px;
              border-bottom: #95c4ff 1px solid;
              .el-icon-success {
                color: #448aff;
                // margin-right: 10px;
              }
              .el-icon-caret-top {
                color: #5e5f60;
                margin-right: 14px;
              }
            }
            .label {
              font-size: 14px;
              font-weight: 550;
              line-height: 20px;
              margin: 10px 0;
            }
          }
        }
        .not_policy_list {
          .not_policy_item {
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 20px;
            padding-bottom: 10px;
            .el-icon-success {
              color: #fd678d;
            }
            span {
              color: #d4d4d4;
            }
          }
        }
      }
    }
  }
}
</style>
